import { gql } from '@apollo/client';

export const GET_MEETING_ROOMS = gql`
    query GetMeetingRooms {
        meetingRooms: meeting_room(where: { deleted_at: { _is_null: true } }, order_by: { capacity: asc }) {
            id
            name
            location {
                id
                name
            }
            capacity
            status {
                value
                title
            }
        }
    }
`;

export const ADD_MEETING_ROOM = gql`
    mutation AddMeetingRoom($room: meeting_room_insert_input!) {
        insert_meeting_room_one(object: $room) {
            id
        }
    }
`;

export const GET_MEETING_ROOM_STATUS = gql`
    query MeetingRoomStatus {
        roomStatus: meeting_room_status {
            value
            title
        }
    }
`;

// export const GET_ROOM_EXTRAS = gql`
//   query MeetingRoomExtra {
//     roomExtras: room_extra(where: { deleted_at: { _is_null: true } }) {
//       id
//       price
//       meetingRoom: meeting_room {
//         id
//       }
//       unit: room_extra_unit {
//         value
//         title
//       }
//     }
//   }
// `;

export const REMOVE_MEETING_ROOM = gql`
    mutation RemoveMeetingRoom($id: uuid!) {
        update_meeting_room_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const INSERT_ROOM_RULES = gql`
    mutation InsertRoomRules($rules: meeting_room_item_rules_insert_input!) {
        insert_meeting_room_item_rules_one(
            object: $rules
            on_conflict: {
                constraint: meeting_room_item_rules_meeting_room_item_id_key
                update_columns: [
                    lead_time
                    trail_time
                    minimum_duration
                    price_on_request
                    allow_long_term
                    allow_repeating
                    overflow_booking
                    allow_short_booking
                    status_enum
                    deleted_at
                    custom_setup_fee
                    cutoff_custom_config
                    cutoff_existing_config
                    base_unit
                ]
            }
        ) {
            id
        }
    }
`;

export const GET_MEETING_ROOM = gql`
    query GetMeetingRoom($id: uuid!) {
        room: meeting_room_by_pk(id: $id) {
            id
            name
            checkListItems: checklist_items(where: { deleted_at: { _is_null: true } }) {
                id
                name
                description
                featuredImage: featured_image
            }
            isConfigurable: is_configurable
            items(where: { deleted_at: { _is_null: true } }, order_by: { name: desc }) {
                id
                name
                useInAnalytics: use_in_analytics
                checklistAddOns: checklist_add_ons(where: { deleted_at: { _is_null: true } }) {
                    id
                    checkListItem: meeting_room_checklist_item {
                        id
                        name
                    }
                }
                itemConfigurations: meeting_room_item_configurations(where: { deleted_at: { _is_null: true } }) {
                    id
                    configurationId: configuration_id
                    meetingRoomItemId: meeting_room_item_id
                    configurations: meeting_room_configurations {
                        name
                        status: room_configuration_status {
                            value
                            title
                        }
                    }
                }
                rules(where: { deleted_at: { _is_null: true } }) {
                    id
                    cutoffCustom: cutoff_custom_config
                    cutoffExisting: cutoff_existing_config
                    customSetupFee: custom_setup_fee
                    minimumDuration: minimum_duration
                    leadTime: lead_time
                    trailTime: trail_time
                    priceOnRequest: price_on_request
                    allowLongTerm: allow_long_term
                    allowRepeating: allow_repeating
                    overflowBooking: overflow_booking
                    allowShortBooking: allow_short_booking
                    baseUnit: base_unit
                    status {
                        value
                        title
                    }
                    exclusions {
                        id
                        meetingRoomItem: meeting_room_item {
                            id
                            name
                        }
                        blockWhenBooked: block_when_booked
                    }
                }
                images(where: { deleted_at: { _is_null: true } }) {
                    id
                    path
                }
                featuredImage: featured_image
                status {
                    title
                    value
                }
                location {
                    id
                    name
                }
            }
            rates(where: { deleted_at: { _is_null: true } }) {
                id
                price
                currency: currency_code
                type: rate_type {
                    value
                    title
                }
            }
            discounts(where: { deleted_at: { _is_null: true } }) {
                id
                name
                description
                percentage
                type: discount_type {
                    value
                    title
                }
            }
            description
            location {
                id
                name
            }
            capacity
            status {
                title
                value
            }
            featuredImage: featured_image
            images(where: { deleted_at: { _is_null: true } }) {
                id
                path
            }
            roomExtras: room_extras(where: { deleted_at: { _is_null: true } }) {
                id
                description
                title
                featuredImagePath: featured_image_path
                variants: room_extra_variants(where: { deleted_at: { _is_null: true } }) {
                    id
                    name
                }
                unit: room_extra_unit {
                    title
                    value
                }
                price
                status: room_extra_status {
                    value
                    title
                }
            }
            meetingRoomConfiguration: meeting_room_configurations(where: { deleted_at: { _is_null: true } }) {
                id
                name
                price
                description
                image: image_path
                status: room_configuration_status {
                    value
                    title
                }
            }
        }
        types: meeting_room_rate_type {
            value
            title
        }
        discountTypes: discount_type {
            value
            title
        }
        location(where: { deleted_at: { _is_null: true } }, order_by: { name: asc }) {
            id
            name
            city
            country
            postal_code
            street_address
        }
        roomStatus: meeting_room_status {
            value
            title
        }
    }
`;

export const UPDATE_MEETING_ROOM = gql`
    mutation UpdateMeetingRoom($id: uuid!, $room: meeting_room_set_input!) {
        update_meeting_room_by_pk(pk_columns: { id: $id }, _set: $room) {
            id
            location_id
        }
    }
`;

export const INSERT_MEETING_ROOM_RATE = gql`
    mutation InsertRoomRate($roomRate: meeting_room_rate_insert_input!) {
        insert_meeting_room_rate_one(
            object: $roomRate
            on_conflict: {
                constraint: meeting_room_rate_meeting_room_id_rate_type_enum_key
                update_columns: [price, currency_code, rate_type_enum, deleted_at]
            }
        ) {
            id
        }
    }
`;

export const INSERT_ROOM_DISCOUNT = gql`
    mutation InsertRoomDiscount($discount: meeting_room_discount_insert_input!) {
        insert_meeting_room_discount_one(
            object: $discount
            on_conflict: {
                constraint: meeting_room_discount_tag_meeting_room_id_key
                update_columns: [name, description, percentage, deleted_at]
            }
        ) {
            id
        }
    }
`;

export const DELETE_EXCLUSION = gql`
    mutation DeleteExclusion($id: uuid!) {
        delete_meeting_room_item_rules_room_exclusions_by_pk(id: $id) {
            id
        }
    }
`;

export const REMOVE_ROOM_RATE = gql`
    mutation RemoveRoomRate($rateId: bigint!) {
        update_meeting_room_rate_by_pk(pk_columns: { id: $rateId }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const REMOVE_ROOM_DISCOUNT = gql`
    mutation RemoveRoomDiscount($dicountId: bigint!) {
        update_meeting_room_discount_by_pk(pk_columns: { id: $dicountId }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const REMOVE_MEETING_ROOM_ITEM = gql`
    mutation RemoveRoomMeetingItem($id: uuid!) {
        update_meeting_room_item_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const ADD_MEETING_ROOM_ITEM = gql`
    mutation AddRoomMeetingItem($item: meeting_room_item_insert_input!) {
        insert_meeting_room_item_one(object: $item) {
            id
        }
    }
`;

export const ADD_MEETING_ROOM_IMAGE = gql`
    mutation AddMeetingRoomImage($image: meeting_room_image_insert_input!) {
        insert_meeting_room_image_one(object: $image) {
            id
        }
    }
`;

export const ADD_MEETING_ROOM_ITEM_IMAGE = gql`
    mutation AddRoomItemImage($image: meeting_room_item_image_insert_input!) {
        insert_meeting_room_item_image_one(object: $image) {
            id
        }
    }
`;

export const REMOVE_MEETING_ROOM_IMAGE = gql`
    mutation RemoveMeetingRoomImage($id: uuid!) {
        update_meeting_room_image_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const REMOVE_MEETING_ROOM_ITEM_IMAGE = gql`
    mutation RemoveMeetingRoomItemImage($id: uuid!) {
        update_meeting_room_item_image_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const GET_MEETING_ITEM_BOOKINGS = gql`
    query getMeetingRoomItemsWithBookings {
        meetingRoomItem: meeting_room_item(order_by: { name: asc }) {
            id
            name
            bookings {
                id
                from: time_from
                to: time_to
                subject
            }
        }
    }
`;

export const GET_DAY_MEETING_ITEM_BOOKINGS = gql`
    query getMeetingRoomItemsWithBookingsForDates($start: timestamp, $end: timestamp) {
        meetingRoomItem: meeting_room_item(
            where: {
                deleted_at: { _is_null: true }
                meeting_room: { deleted_at: { _is_null: true }, status_enum: { _eq: "active" } }
            }
            order_by: { name: asc }
        ) {
            id
            name
            itemConfigurations: meeting_room_item_configurations(where: { deleted_at: { _is_null: true } }) {
                id
                configurations: meeting_room_configurations {
                    id
                    image: image_path
                    name
                }
            }
            checklistAddOns: checklist_add_ons(where: { deleted_at: { _is_null: true } }) {
                id
                checkListItem: meeting_room_checklist_item {
                    id
                    name
                }
            }
            status {
                value
                title
            }
            meetingRoom: meeting_room {
                id
                name
                roomExtras: room_extras(where: { deleted_at: { _is_null: true } }) {
                    id
                    price
                    title
                    description
                    unit: room_extra_unit {
                        title
                        value
                    }
                }
                discounts {
                    id
                    percentage
                    type: discount_type {
                        value
                        title
                    }
                }
                rates {
                    price
                    type: rate_type {
                        value
                        title
                    }
                }
            }
            rules {
                id
                allowRepeating: allow_repeating
                allowLongTerm: allow_long_term
                allowShortBooking: allow_short_booking
                overflowBooking: overflow_booking
            }
            bookings(
                where: {
                    _and: {
                        time_from: { _gte: $start }
                        time_to: { _lte: $end }
                        status_enum: { _nin: [expired, cancelled] }
                        deleted_at: { _is_null: true }
                    }
                }
            ) {
                id
                from: time_from
                to: time_to
                subject
                client: user_account {
                    companyName: company_name
                    individualName: individual_name
                }
            }
        }
    }
`;

export const CHECK_MEETING_ROOM_ITEM_AVAILABILITY = gql`
    query CheckMeetingRoomAvailability($meetingRoomItemId: String!, $from: String!, $to: String!) {
        booking: check_meeting_room_availability(meetingRoomItemId: $meetingRoomItemId, from: $from, to: $to) {
            isAvailable: is_available
        }
    }
`;

export const CHECK_IS_MEETING_ROOM_ITEM_AVAILABILITY = gql`
    mutation checkRoomAvailability($args: is_room_available_args!) {
        is_room_available(args: $args) {
            isAvailable: is_available
        }
    }
`;

export const CHECK_IS_MEETING_ROOM_AVAILABLE_EXTENDED = gql`
    mutation checkRoomAvailabilityExtended($args: is_room_available_extended_args!) {
        result: is_room_available_extended(args: $args) {
            isAvailable: is_available
        }
    }
`;

export const CHECK_IS_CLOSED_TIME = gql`
    mutation checkBookingClosedDate($args: is_booking_in_closed_time_args!) {
        closeDate: is_booking_in_closed_time(args: $args) {
            bookingDate: booking_date
            isClosedTime: is_closed_time
        }
    }
`;

export const UPDATE_MEETING_ROOM_ITEM = gql`
    mutation UpdateRoomItem($id: uuid!, $item: meeting_room_item_set_input!) {
        update_meeting_room_item_by_pk(pk_columns: { id: $id }, _set: $item) {
            id
        }
    }
`;

export const GET_MEETING_ROOM_ITEM = gql`
    query getMeetingRoomItem($id: uuid!) {
        getMeetingRoomItem: meeting_room_item_by_pk(id: $id) {
            id

            rules(where: { deleted_at: { _is_null: true } }) {
                id
                cutoffCustom: cutoff_custom_config
                cutoffExisting: cutoff_existing_config
                customSetupFee: custom_setup_fee
                minimumDuration: minimum_duration
                leadTime: lead_time
                trailTime: trail_time
                priceOnRequest: price_on_request
                allowLongTerm: allow_long_term
                allowRepeating: allow_repeating
                overflowBooking: overflow_booking
                allowShortBooking: allow_short_booking
                status {
                    value
                    title
                }
                exclusions {
                    id
                    meetingRoomItem: meeting_room_item {
                        id
                        name
                    }
                    blockWhenBooked: block_when_booked
                }
            }
            itemConfigurations: meeting_room_item_configurations {
                id
                configurationId: configuration_id
                meetingRoomItemId: meeting_room_item_id
                configurations: meeting_room_configurations {
                    name
                    configurationStatus: room_configuration_status {
                        title
                        value
                    }
                }
            }
        }
    }
`;

export const ASSIGN_CONFIGURATION = gql`
    mutation AssignConfiguration($assign: meeting_room_item_configuration_insert_input!) {
        addConfiguration: insert_meeting_room_item_configuration_one(object: $assign) {
            id
        }
    }
`;
export const REMOVE_ASSIGNED_CONFIGURATION = gql`
    mutation removeAssignedConfiguration($id: uuid!) {
        removeConfiguration: update_meeting_room_item_configuration_by_pk(
            pk_columns: { id: $id }
            _set: { deleted_at: now }
        ) {
            id
        }
    }
`;

export const ADD_MEETING_ROOM_CONFIGURATION = gql`
    mutation AddMeetingRoomConfiguration($configuration: meeting_room_configuration_insert_input!) {
        insert_meeting_room_configuration_one(object: $configuration) {
            id
        }
    }
`;

export const UPDATE_MEETING_ROOM_CONFIGURATION = gql`
    mutation UpdateConfiguration($id: uuid!, $configuration: meeting_room_configuration_set_input!) {
        update_meeting_room_configuration_by_pk(pk_columns: { id: $id }, _set: $configuration) {
            id
        }
    }
`;

export const MEETING_CONFIGURATION_STATUS = gql`
    query GetMeetingRoomConfigurationStatus {
        status: room_configuration_status {
            value
            title
        }
    }
`;

export const MEETING_ROOM_CONFIGURATIONS = gql`
    query getMeetingRoomConfiguration {
        meetingRoomConfiguration: meeting_room_configuration(where: { meeting_room_item_id: { _is_null: false } }) {
            id
            name
            description
            meetingRoomItemId: meeting_room_item_id
            image: image_path
            status: room_configuration_status {
                value
                title
            }
        }
    }
`;

export const REMOVE_ROOM_CONFIGURATION = gql`
    mutation RemoveMeetingRoomConfiguration($id: uuid!) {
        update_meeting_room_configuration_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const ADD_ROOM_EXTRA = gql`
    mutation InsertExtra($extra: room_extra_insert_input!) {
        insert_room_extra_one(
            object: $extra
            on_conflict: {
                constraint: room_extra_pkey
                update_columns: [status_enum, unit, price, description, title, featured_image_path]
            }
        ) {
            id
        }
    }
`;

export const GET_ROOM_EXTRA_STATUS = gql`
    query GetRoomExtraStatusList {
        status: room_extra_status {
            value
            title
        }
    }
`;

export const GET_ROOM_EXTRA_UNIT = gql`
    query GetRoomExtraUnitList {
        units: room_extra_unit {
            value
            title
        }
    }
`;

export const UPDATE_ROOM_EXTRA = gql`
    mutation UpdateRoomExtra($id: uuid!, $room_extra: room_extra_set_input!) {
        update_room_extra_by_pk(pk_columns: { id: $id }, _set: $room_extra) {
            id
        }
    }
`;

export const REMOVE_ROOM_EXTRA = gql`
    mutation RemoveRoomExtra($id: uuid!) {
        update_room_extra_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const REMOVE_ROOM_EXTRA_VARIANT = gql`
    mutation RemoveRoomExtravariant($id: uuid!) {
        update_room_extra_variant_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const REMOVE_ROOM_ITEM_CONFIGURATION = gql`
    mutation RemoveRoomConfiguration($id: uuid!) {
        update_meeting_room_configuration_by_pk(pk_columns: { id: $id }, _set: { meeting_room_item_id: null }) {
            id
        }
    }
`;

export const GET_BOOKING_VERIFICATIONS = gql`
    query GetBookingVerifications {
        verifications: booking_verification(where: { deleted_at: { _is_null: true } }, order_by: { created_at: desc }) {
            id
            createdAt: created_at
            newBooking: new_booking {
                id
                quotedPrice: quoted_price
                booking {
                    id
                    bookingReference: booking_number
                    subject
                }
            }
            oldBooking: old_booking {
                id
                quotedPrice: quoted_price
            }
            type {
                value
                title
            }
            status {
                value
                title
            }
        }
    }
`;

export const REMOVE_VERIFICATION = gql`
    mutation RemoveVerification($id: uuid!) {
        update_booking_verification_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const GET_BOOKING_VERIFICATION_DETAILS = gql`
    query GetBookingVerificationDetails($id: uuid!) {
        verification: booking_verification_by_pk(id: $id) {
            id
            createdAt: created_at
            status {
                value
                title
            }
            reason
            newBooking: new_booking {
                id
                from: time_from
                to: time_to
                subject
                booking {
                    childrenBookings: children_bookings {
                        id
                        subject
                        from: time_from
                        to: time_to
                        totalPrice: total_price
                        discount
                        quotedPrice: quoted_price
                        quantity
                    }
                }
                price
                unit
                billingPeriod: billing_period
                totalPrice: total_price
                discount
                quotedPrice: quoted_price
                quantity
                meetingRoomItem: meeting_room_item {
                    id
                    name
                }
                status {
                    value
                    title
                }
            }
            oldBooking: old_booking {
                id
                from: time_from
                to: time_to
                subject
                booking {
                    childrenBookings: children_bookings {
                        id
                        subject
                        from: time_from
                        to: time_to
                        totalPrice: total_price
                        discount
                        quotedPrice: quoted_price
                        quantity
                    }
                }
                price
                unit
                billingPeriod: billing_period
                totalPrice: total_price
                discount
                quotedPrice: quoted_price
                quantity
                meetingRoomItem: meeting_room_item {
                    id
                    name
                }
                status {
                    value
                    title
                }
            }
            type {
                value
                title
            }
            status {
                value
                title
            }
        }
    }
`;

export const REJECT_VERIFICATION = gql`
    mutation RejectVerification($verificationId: uuid!) {
        update_booking_verification_by_pk(pk_columns: { id: $verificationId }, _set: { status_enum: cancelled }) {
            id
        }
    }
`;

export const APPROVE_VERIFICATION = gql`
    mutation ApproveVerification($verificationId: uuid!) {
        update_booking_verification_by_pk(pk_columns: { id: $verificationId }, _set: { status_enum: confirmed }) {
            id
        }
    }
`;

export const GET_TODAY_INCOME_DIFFERENCE = gql`
    query GetDifferenceAndIncomeData($args: get_current_day_income_difference_args!) {
        incomeData: get_current_day_income_difference(args: $args) {
            date
            actualIncome: actual_income
            expectedIncome: expected_income
            difference
        }
    }
`;
