import { gql } from '@apollo/client';

export const GET_CLIENTS = gql`
    query GetClients {
        clients: user_account(where: { deleted_at: { _is_null: true } }, order_by: { company_name: asc }) {
            id
            members: team_members {
                id
                sendCommunication: send_communication
                user {
                    name
                    surname
                    email
                }
            }
            companyName: company_name
            individualName: individual_name
            type {
                value
                title
            }
            status {
                value
                title
            }
            customConferencePrice: conference_custom_prices {
                id
                price
                meetingRoom: meeting_room {
                    id
                }
                currencyCode: currency_code
                rateType: rate_type {
                    value
                    title
                }
            }
            isColabMember: is_colab_member
            contractExpiry: contract_expiry_date
            user {
                id
                name
                surname
                contactNumber: contact_number
                email
            }
        }
    }
`;

export const GET_CLIENT = gql`
    query GetClient($id: uuid!) {
        client: user_account_by_pk(id: $id) {
            id
            companyName: company_name
            hasAccount: has_colab_account
            isPrecinctTenant: is_precinct_tenant
            allowAddToAccount: allow_add_to_account
            includeCalendarInvites: include_calendar_invites
            accountDocument: account_document {
                id
                name
                description
                path
                type: account_document_type {
                    value
                    title
                }
            }
            individualName: individual_name
            type {
                value
                title
            }
            accountDocuments: account_documents(where: { deleted_at: { _is_null: true } }) {
                id
                name
                description
                path
                date: created_at
                type: account_document_type {
                    value
                    title
                }
                documentTypes: account_document_type {
                    title
                    value
                }
            }
            orders(where: { deleted_at: { _is_null: true } }) {
                id
                reference
                invoicePath: invoice_path
                createdAt: created_at
                status: order_status {
                    value
                    title
                }
                paymentStatus: payment_status {
                    value
                    title
                }
            }
            vatNumber: vat_number
            companyRegNumber: company_registration_number
            accountCode: account_code
            isColabMember: is_colab_member
            contractExpiry: contract_expiry_date
            status {
                value
                title
            }
            addresses(where: { main_address: { _eq: true } }, limit: 1, order_by: { created_at: asc }) {
                id
                building
                street
                suburb
                city
                province
                country
                postalCode: postal_code
            }
            packages: package_bookings(
                where: { deleted_at: { _is_null: true }, status_enum: { _neq: expired } }
                order_by: { from: desc }
            ) {
                id
                from
                to
                assignedItemId: product_item_id
                assignable: is_assignable
                status: booking_status {
                    value
                    title
                }
                member: account_team_member {
                    id
                    user {
                        name
                        surname
                    }
                }
                productPackage: product_package {
                    id
                    name
                    isAssignable: is_assignable
                }
            }
            bookings(
                where: {
                    _and: [
                        { deleted_at: { _is_null: true } }
                        { status_enum: { _nin: [expired, transferred, pending_review] } }
                    ]
                }
                order_by: { time_from: desc }
            ) {
                id
                from: time_from
                to: time_to
                subject
                bookingReference: booking_number
                additionalNotes: additional_notes
                client: user_account {
                    id
                }
                meetingRoomItem: meeting_room_item {
                    id
                    name
                }
                status {
                    title
                    value
                }
            }
            members: team_members(where: { deleted_at: { _is_null: true } }) {
                id
                status {
                    value
                    title
                }
                role {
                    id
                    name
                }
                user {
                    id
                    name
                    surname
                    contactNumber: contact_number
                    email
                    identityNumber: id_number
                    licencePlate: licence_plate
                    role {
                        id
                        name
                    }
                }
            }
            user {
                id
                name
                surname
                contactNumber: contact_number
                identityNumber: id_number
                licencePlate: licence_plate
                email
            }
        }
    }
`;

export const UPDATE_CLIENT_DETAILS = gql`
    mutation UpdateUserAccount(
        $account_id: uuid!
        $user_account: user_account_set_input!
        $user_id: uuid!
        $user: user_set_input!
    ) {
        update_user_account_by_pk(pk_columns: { id: $account_id }, _set: $user_account) {
            id
        }

        update_user_by_pk(pk_columns: { id: $user_id }, _set: $user) {
            id
        }
    }
`;

export const UPDATE_ACCOUNT_SETTINGS = gql`
    mutation UpdateAccountSettings($id: uuid!, $account: user_account_set_input!) {
        client: update_user_account_by_pk(pk_columns: { id: $id }, _set: $account) {
            id
        }
    }
`;

export const ADD_CLIENT = gql`
    mutation AddClient($user_account: user_account_insert_input!) {
        client: insert_user_account_one(object: $user_account) {
            id
        }
    }
`;

export const REMOVE_CLIENT = gql`
    mutation RemoveClient($id: uuid!) {
        update_user_account_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const REMOVE_MEMBER = gql`
    mutation RemoveClientMember($id: uuid!) {
        update_account_team_member_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const GET_BOOKING_STATUS = gql`
    query GetBookingStatus {
        status: booking_status {
            title
            value
        }
    }
`;

export const GET_BOOKING_DATA = gql`
    query GetBookingData {
        status: booking_status {
            title
            value
        }
        meetingRoomItems: meeting_room_item(order_by: { name: asc }) {
            id
            name
        }
    }
`;

export const GET_MEETING_ROOM_ITEMS = gql`
    query GetMeetingRooms {
        meetingRoomItems: meeting_room_item(
            where: {
                deleted_at: { _is_null: true }
                status_enum: { _neq: "archived" }
                meeting_room: { deleted_at: { _is_null: true }, status_enum: { _eq: "active" } }
            }
            order_by: { name: asc }
        ) {
            id
            name
            status {
                value
                title
            }
            checklistAddOns: checklist_add_ons(where: { deleted_at: { _is_null: true } }) {
                id
                checkListItem: meeting_room_checklist_item {
                    id
                    name
                }
            }
            itemConfigurations: meeting_room_item_configurations(where: { deleted_at: { _is_null: true } }) {
                id
                configurations: meeting_room_configurations {
                    id
                    image: image_path
                    name
                }
            }
            rules {
                id
                allowRepeating: allow_repeating
                allowLongTerm: allow_long_term
                allowShortBooking: allow_short_booking
                overflowBooking: overflow_booking
            }
            meetingRoom: meeting_room {
                id
                name
                roomExtras: room_extras(where: { deleted_at: { _is_null: true } }) {
                    id
                    price
                    title
                    description
                    unit: room_extra_unit {
                        title
                        value
                    }
                }
                rates {
                    price
                    type: rate_type {
                        value
                        title
                    }
                }
            }
        }
    }
`;

export const ADD_BOOKING = gql`
    mutation AddBooking($booking: booking_insert_input!) {
        booking: insert_booking_one(
            object: $booking
            on_conflict: {
                constraint: booking_pkey
                update_columns: [
                    additional_notes
                    billing_period
                    meeting_room_item_id
                    discount
                    price
                    unit
                    subject
                    time_from
                    time_to
                    status_enum
                    quoted_price
                    total_price
                    discount
                    price
                    voucher_code
                    is_long_term
                    quantity
                    configuration_id
                    access_code
                    include_calendar_invites
                    send_communications
                    has_custom_pricing
                    voucher_id
                    price_change_reason
                ]
            }
        ) {
            id
            roomExtras: room_extras {
                id
                quantity
                totalPrice: total_price
                rate
                roomExtra: room_extra {
                    id
                    title
                }
            }
            menuPackages: menu_packages {
                id
                unitPrice: price
                vendorName: vendor_name
                totalPrice: total_price
                menuPackageName: menu_package_name
                menuPackageId: menu_package_id
                featuredImage: featured_image
                vendorId: vendor_id
                quantity
            }
            bookingResources: booking_after_hour_resources {
                id
                bookingId: booking_id
                name
                quantity
                rate
                resourceId: resource_id
                totalPrice: total_price
            }
        }
    }
`;
export const REMOVE_BOOKING = gql`
    mutation RemoveBooking($id: uuid!) {
        update_booking_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const ADD_MEMBER = gql`
    mutation CreateTeamMember($accountId: String!, $user: CreateTeamUserInput!, $invitedById: String) {
        create_team_member(accountId: $accountId, user: $user, invitedById: $invitedById) {
            id
        }
    }
`;

export const GET_PACKAGES = gql`
    query GetPackages {
        packages: product_package(where: { deleted_at: { _is_null: true } }) {
            id
            name
            isAssignable: is_assignable
            product {
                id
                productItems: product_items {
                    id
                    name
                    code
                    sharable: is_sharable
                    status {
                        value
                        title
                    }
                }
            }
        }
        status: booking_status {
            title
            value
        }
        productItems: product_item {
            id
            name
            sharable: is_sharable
            code
            status {
                value
                title
            }
        }
    }
`;

export const ADD_PACKAGE = gql`
    mutation AddPackage($package: package_booking_insert_input!) {
        insert_package_booking_one(object: $package) {
            id
        }
    }
`;

export const REMOVE_PACKAGE = gql`
    mutation RemovePackage($id: uuid!) {
        update_package_booking_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const GET_CLIENT_MEMBER = gql`
    query GetClientMember($id: uuid!) {
        member: account_team_member_by_pk(id: $id) {
            id
            sendCommunication: send_communication
            role {
                id
                name
            }
            status {
                value
                title
            }
            user {
                id
                name
                surname
                email
                contactNumber: contact_number
                licencePlate: licence_plate
                identityNumber: id_number
                roleId: role_id
                dateOfBirth: date_of_birth
            }
        }
        roles: role(where: { level: { _eq: "client" } }, order_by: { name: asc }) {
            id
            tag
            name
        }
        memberStatus: account_team_member_status {
            value
            title
        }
    }
`;

export const UPDATE_CLIENT_MEMBER = gql`
    mutation UpdateClientMember($id: uuid!, $member: account_team_member_set_input!) {
        update_account_team_member_by_pk(pk_columns: { id: $id }, _set: $member) {
            id
            user {
                id
            }
        }
    }
`;

export const GET_ADD_CLIENT_DATA = gql`
    query GetAddClientData {
        status: account_status {
            value
            title
        }
        types: user_account_type {
            value
            title
        }
        role(where: { level: { _eq: "client" } }) {
            id
            name
        }
    }
`;

export const INSERT_CLIENT_ADDRESS = gql`
    mutation InsertClientAddress($address: address_insert_input!) {
        address: insert_address_one(
            object: $address
            on_conflict: {
                constraint: address_pkey
                update_columns: [building, street, suburb, city, province, country, postal_code]
            }
        ) {
            id
        }
    }
`;

export const ADD_ACCOUNT_DOCUMENT = gql`
    mutation addAccountDocument($document: account_document_insert_input!) {
        insert_account_document_one(
            object: $document
            on_conflict: {
                constraint: account_document_pkey
                update_columns: [name, description, path, type_enum, deleted_at]
            }
        ) {
            id
        }
    }
`;

export const REMOVE_ACCOUNT_DOCUMENT = gql`
    mutation removeAccountDocument($id: uuid!) {
        update_account_document_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const GET_DOCUMENT_TYPES = gql`
    query getDocumentTypes {
        documentTypes: account_document_type {
            value
            title
        }
    }
`;

export const SEARCH_ACCOUNTS = gql`
    query SearchAccounts($query: String) {
        accounts: user_account(
            where: {
                _or: [{ company_name: { _ilike: $query } }, { individual_name: { _ilike: $query } }]
                deleted_at: { _is_null: true }
            }
            order_by: { company_name: asc }
            limit: 10
        ) {
            id
            companyName: company_name
            individualName: individual_name
            includeCalendarInvites: include_calendar_invites
            members: team_members(where: { send_communication: { _eq: true } }) {
                id
                user {
                    name
                    surname
                }
            }
        }
    }
`;

export const GET_CLIENT_TEAM_MEMBERS = gql`
    query GetClientTeamMembers($accountId: uuid!) {
        members: account_team_member(
            where: { _and: [{ account_id: { _eq: $accountId } }, { send_communication: { _eq: true } }] }
        ) {
            id
            user {
                name
                surname
            }
        }
    }
`;

export const SEARCH_ACCOUNT_MEMBERS = gql`
    query SearchAccountMember($accountId: uuid!, $query: String) {
        members: account_team_member(
            where: {
                _and: [
                    { account_id: { _eq: $accountId } }
                    { _or: [{ user: { name: { _ilike: $query } } }, { user: { surname: { _ilike: $query } } }] }
                ]
            }
        ) {
            id
            user {
                id
                name
                surname
            }
        }
    }
`;

export const GET_CUSTOM_PRICING_DATA = gql`
    query GetCustomPricingData($accountId: uuid!) {
        meetingRooms: meeting_room(where: { deleted_at: { _is_null: true } }, order_by: { name: asc }) {
            id
            name
        }
        productPackages: product_package(where: { deleted_at: { _is_null: true } }, order_by: { name: asc }) {
            id
            name
        }
        rateTypes: meeting_room_rate_type(order_by: { title: asc }) {
            value
            title
        }
        customPackagePricing: account_package_custom_price(
            where: { account_id: { _eq: $accountId }, deleted_at: { _is_null: true } }
            order_by: { product_package: { name: asc } }
        ) {
            id
            package: product_package {
                id
                name
            }
            price
            currencyCode: currency_code
        }
        customPricing: account_conference_custom_price(
            where: { account_id: { _eq: $accountId }, deleted_at: { _is_null: true } }
            order_by: { meeting_room: { name: asc } }
        ) {
            id
            meetingRoom: meeting_room {
                id
                name
            }
            price
            currencyCode: currency_code
            rateType: rate_type {
                value
                title
            }
        }
    }
`;

export const INSERT_CUSTOM_CONFERENCING_PRICE = gql`
    mutation InsertCustomConferencePrice($customPrice: account_conference_custom_price_insert_input!) {
        insert_account_conference_custom_price_one(
            object: $customPrice
            on_conflict: {
                constraint: account_conference_custom_price_meeting_room_id_account_id_rate
                update_columns: [price, currency_code, rate_type_enum, deleted_at]
            }
        ) {
            id
        }
    }
`;

export const INSERT_CUSTOM_PACKAGE_PRICE = gql`
    mutation InsertCustomPackagePrice($customPrice: account_package_custom_price_insert_input!) {
        insert_account_package_custom_price_one(
            object: $customPrice
            on_conflict: {
                constraint: account_package_custom_price_account_id_package_id_key
                update_columns: [price, currency_code, deleted_at]
            }
        ) {
            id
        }
    }
`;

export const REMOVE_CUSTOM_CONFERENCING_PRICE = gql`
    mutation RemoveCustomPricing($id: uuid!) {
        update_account_conference_custom_price_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const REMOVE_CUSTOM_PACKAGE_PRICE = gql`
    mutation RemoveCustomPackagePricing($id: uuid!) {
        update_account_package_custom_price_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const EXPORT_CLIENTS = gql`
    mutation ExportClients($type: String!) {
        clients: export_clients(type: $type) {
            path
        }
    }
`;
