import { useMutation, useQuery } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, CardText, Col, Container, Row } from 'reactstrap';
import {
    APPROVE_VERIFICATION,
    GET_BOOKING_VERIFICATION_DETAILS,
    REJECT_VERIFICATION,
} from '../../../graphql/meeting-rooms.graphql';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import useToast from '../../../hooks/useToast';

export default function VerificationDetails() {
    const navigation = useNavigate();
    const params: any = useParams();
    const { showToast, Notification } = useToast();
    const [rejectAlert, setRejectAlert] = useState<any>(null);
    const [approveAlert, setApproveAlert] = useState<any>(null);

    const { loading, data } = useQuery(GET_BOOKING_VERIFICATION_DETAILS, {
        variables: { id: params.id },
    });

    const [rejectVerification, { error: rejectError, called: rejectCalled }] = useMutation(REJECT_VERIFICATION, {
        refetchQueries: ['GetBookingVerifications', GET_BOOKING_VERIFICATION_DETAILS],
    });

    const [approveVerification, { error: approveError, called: approvedCalled }] = useMutation(APPROVE_VERIFICATION, {
        refetchQueries: ['GetBookingVerifications', GET_BOOKING_VERIFICATION_DETAILS],
    });

    useEffect(() => {
        if (approvedCalled && !approveError) {
            showToast('Successfully approved booking change', 'success');
        }

        if (approveError) {
            showToast('An error has occured, please try again', 'danger');
        }
    }, [approveError, approvedCalled, navigation, showToast]);

    useEffect(() => {
        if (rejectCalled && !rejectError) {
            showToast('Successfully rejected booking change', 'success');
            navigation(-1);
        }

        if (rejectError) {
            showToast('An error has occured, please try again', 'danger');
        }
    }, [rejectError, rejectCalled, showToast, navigation]);

    if (loading) {
        return <LinearProgress />;
    }

    const oldBooking = data?.verification.oldBooking;
    const newBooking = data?.verification.newBooking;

    const displayRejectAlert = (props: any) => {
        setRejectAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    rejectVerification({
                        variables: {
                            verificationId: params.id,
                        },
                    });
                    setRejectAlert(null);
                }}
                onCancel={() => {
                    setRejectAlert(null);
                }}
                focusCancelBtn>
                This booking change will be rejected.
            </SweetAlert>,
        );
    };

    const displayApproveAlert = (props: any) => {
        setApproveAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='success'
                title='Are you sure?'
                onConfirm={() => {
                    approveVerification({
                        variables: {
                            verificationId: params.id,
                        },
                    });
                    setApproveAlert(null);
                }}
                onCancel={() => {
                    setApproveAlert(null);
                }}
                focusCancelBtn>
                This current booking will be replaced with the new booking.
            </SweetAlert>,
        );
    };

    return (
        <>
            {Notification}
            {rejectAlert}
            {approveAlert}
            <Container>
                <Row className='mt-4' noGutters>
                    <button
                        className='btn btn-outline-primary btn-sm mr-4'
                        onClick={() => navigation('/admin/booking-verifications')}>
                        <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                        <span className='btn-inner-text'>Back</span>
                    </button>
                    <h1 className='mb-0'>Verification details</h1>
                    {data.verification.status.value === 'pending' && (
                        <Button
                            color='success'
                            size='sm'
                            className='ml-auto'
                            onClick={() => {
                                displayApproveAlert({});
                            }}>
                            Approve
                        </Button>
                    )}
                    {data.verification.status.value === 'pending' && (
                        <Button
                            color='danger'
                            size='sm'
                            className='ml-2'
                            onClick={() => {
                                displayRejectAlert({});
                            }}>
                            Reject
                        </Button>
                    )}
                </Row>
                <Row className='mt-4'>
                    {oldBooking && (
                        <Col md='12' lg='6'>
                            <Card>
                                <CardHeader className='border-0'>
                                    <h3 className='mb-0'>Old Booking</h3>
                                </CardHeader>
                                <CardBody>
                                    <CardText>Subject: {oldBooking?.subject} </CardText>
                                    <CardText>Meeting Room: {oldBooking.meetingRoomItem.name}</CardText>

                                    <CardText>From: {moment(oldBooking?.from).format('YYYY-MM-DD HH:mm')}</CardText>
                                    <CardText>To: {moment(oldBooking?.to).format('YYYY-MM-DD HH:mm')}</CardText>
                                    <CardText>Subtotal: R{oldBooking?.totalPrice?.toFixed(2)}</CardText>
                                    <CardText>Discount: R{oldBooking?.discount?.toFixed(2)}</CardText>
                                    <CardText className='text-strong'>
                                        Total: R{oldBooking?.quotedPrice?.toFixed(2)}
                                    </CardText>
                                    {oldBooking?.booking?.childrenBookings?.map((booking) => (
                                        <div
                                            style={{ borderTop: '1px solid #e5e5e5', padding: '10px' }}
                                            key={booking.id}
                                            className='mt-4'>
                                            <CardText>Subject: {oldBooking?.subject} </CardText>
                                            <CardText>Meeting Room: {oldBooking.meetingRoomItem.name}</CardText>

                                            <CardText>
                                                From: {moment(booking?.from).format('YYYY-MM-DD HH:mm')}
                                            </CardText>
                                            <CardText>To: {moment(booking?.to).format('YYYY-MM-DD HH:mm')}</CardText>
                                            <CardText>Subtotal: R{booking?.totalPrice?.toFixed(2)}</CardText>
                                            <CardText>Discount: R{oldBooking?.discount?.toFixed(2)}</CardText>
                                            <CardText className='text-strong'>
                                                Total: R{oldBooking?.quotedPrice?.toFixed(2)}
                                            </CardText>
                                        </div>
                                    ))}
                                </CardBody>
                            </Card>
                        </Col>
                    )}

                    <Col md='12' lg='6'>
                        <Card>
                            <CardHeader className='border-0'>
                                <h3 className='mb-0'>New Booking</h3>
                            </CardHeader>
                            <CardBody>
                                <CardText>Subject: {newBooking?.subject} </CardText>
                                <CardText>Meeting Room: {newBooking.meetingRoomItem.name}</CardText>

                                <CardText>From: {moment(newBooking?.from).format('YYYY-MM-DD HH:mm')}</CardText>
                                <CardText>To: {moment(newBooking?.to).format('YYYY-MM-DD HH:mm')}</CardText>
                                <CardText>Subtotal: R{newBooking?.totalPrice?.toFixed(2)}</CardText>
                                <CardText>Discount: R{newBooking?.discount?.toFixed(2)}</CardText>
                                <CardText className='text-strong'>
                                    Total: R{newBooking?.quotedPrice?.toFixed(2)}
                                </CardText>
                                {newBooking?.booking?.childrenBookings?.map((booking) => (
                                    <div
                                        style={{ borderTop: '1px solid #e5e5e5', padding: '10px' }}
                                        key={booking.id}
                                        className='mt-4'>
                                        <CardText>Subject: {newBooking?.subject} </CardText>
                                        <CardText>Meeting Room: {newBooking.meetingRoomItem.name}</CardText>

                                        <CardText>From: {moment(booking?.from).format('YYYY-MM-DD HH:mm')}</CardText>
                                        <CardText>To: {moment(booking?.to).format('YYYY-MM-DD HH:mm')}</CardText>
                                        <CardText>Subtotal: R{booking?.totalPrice?.toFixed(2)}</CardText>
                                        <CardText>Discount: R{newBooking?.discount?.toFixed(2)}</CardText>
                                        <CardText className='text-strong'>
                                            Total: R{newBooking?.quotedPrice?.toFixed(2)}
                                        </CardText>
                                    </div>
                                ))}
                            </CardBody>
                        </Card>
                    </Col>
                    {data.verification?.reason && (
                        <Col md='12' lg='6'>
                            <Card>
                                <CardHeader className='border-0'>
                                    <h3 className='mb-0'>Price Change Reason</h3>
                                </CardHeader>
                                <CardBody>
                                    <CardText>{data.verification?.reason}</CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                </Row>
            </Container>
        </>
    );
}
