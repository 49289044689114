/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import { MeetingRoomItem } from '../models/meeting.room.model';
import { Client } from '../models/client.model';
import {
    Booking,
    BookingFormData,
    BookingRate,
    BookingRoomExtra,
    BookingStatus,
    ConferenceBookingDocument,
    ConferenceBookingNote,
    NominatedMember,
} from '../models/bookings.model';
import { ADD_BOOKING } from '../graphql/clients.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { GET_BOOKING_DETAILS, SEND_EMAIL_TO_MEMBERS } from '../graphql/bookings.graphql';
import { Spinner } from 'reactstrap';
import useToast from '../hooks/useToast';
import {
    CHECK_IS_MEETING_ROOM_ITEM_AVAILABILITY,
    CHECK_IS_MEETING_ROOM_AVAILABLE_EXTENDED,
} from '../graphql/meeting-rooms.graphql';
import { GET_BOOKING_RATE } from '../graphql/booking.price.graphql';
import { ADD_QUOTE_ITEM } from '../graphql/quotes.graphql';
import { useForm } from 'react-hook-form';
import { getTime } from '../utils/booking.utils';
import moment from 'moment';
import { generate } from 'randomstring';
import ExtendedBookingForm from './Booking forms/ExtendedBookingForm';
import { VendorMenuPackageItem } from '../models/vendor.model';
import { GET_CATERING_VENDORS } from '../graphql/vendors.graphql';
import { LinearProgress } from '@mui/material';
import { GET_AFTER_HOUR_RESOURCES } from '../graphql/after-hour-resources.graphql';
import { BookingResource } from '../models/resources.model';
import { v4 } from 'uuid';
import { Storage } from 'aws-amplify';
import { useSelector } from 'react-redux';

interface BookingDialogProps {
    open: boolean;
    handleClose?: () => void;
    rooms: MeetingRoomItem[];
    bookingId?: string;
    clientId?: string;
    clients?: Client[];
    statusList?: BookingStatus[];
    roomItemId?: string;
    quoteId?: string;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const BookingDialog = ({
    open,
    handleClose,
    bookingId,
    clientId,
    statusList,
    rooms,
    roomItemId,
    quoteId,
}: BookingDialogProps) => {
    const {
        loading: loadingBooking,
        data: loadedBooking,
        called,
    } = useQuery(GET_BOOKING_DETAILS, {
        variables: {
            id: bookingId,
        },
        skip: !bookingId,
        fetchPolicy: 'network-only',
    });
    const [showFullScreen, setShowFullScreen] = useState<boolean>(true);
    const [availabilityText, setAvailabilityText] = useState<string>('');
    const [isAvailable, setIsAvailable] = useState<boolean>(true);
    const [isClosedDate, setIsClosedDate] = useState<boolean>(false);
    const [configurationId, setConfigurationId] = useState<string | null>();
    const [bookingResources, setBookingResources] = useState<BookingResource[]>([]);

    const [bookingRoomAdOns, setBookingRoomAdOns] = useState<BookingRoomExtra[]>([]);

    const [documents, setDocuments] = useState<ConferenceBookingDocument[]>([]);
    const [notes, setNotes] = useState<ConferenceBookingNote[]>([]);
    const user = useSelector((state: any) => state.user.userInfo);

    const [voucherId, setVoucherId] = useState<string>();
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const [nominatedMembers, setNominatedMembers] = useState<NominatedMember[]>();
    const [sendEmailToMembers, { data: emailSent, error: emailError }] = useMutation(SEND_EMAIL_TO_MEMBERS);
    const [formData, setFormData] = useState<BookingFormData>();
    const [addingQuote, setAddingQuote] = useState<boolean>(false);
    const [bookingRate, setBookingRate] = useState<BookingRate>();
    const { data } = useQuery(GET_CATERING_VENDORS);
    const { data: afterHourResource } = useQuery(GET_AFTER_HOUR_RESOURCES);
    const [selectedMenuItems, setSelectedMenuItems] = useState<VendorMenuPackageItem[]>([]);
    const [addQuoteItem, { error: quoteError, data: addedQuote }] = useMutation(ADD_QUOTE_ITEM, {
        refetchQueries: ['getQuote'],
    });
    const [addBookingRate, { data: rateData }] = useMutation(GET_BOOKING_RATE, {
        fetchPolicy: 'network-only',
    });
    const { showToast, Notification } = useToast();
    const [sendEmail, setSendEmail] = useState<boolean>(false);

    const [checkMeetingRoom, { loading: checking, error: checkingError, data: meetingRoom, called: calledToCheck }] =
        useMutation(CHECK_IS_MEETING_ROOM_ITEM_AVAILABILITY, {
            fetchPolicy: 'network-only',
        });

    const [checkAvailabilityForRecurring, { loading: checkingAvailability, data: meetingRoomItem }] = useMutation(
        CHECK_IS_MEETING_ROOM_AVAILABLE_EXTENDED,
        {
            fetchPolicy: 'network-only',
        },
    );

    const [addBookingMutation, { loading: addingBooking, error: errorAdding, data: added }] = useMutation(ADD_BOOKING, {
        refetchQueries: ['GetBookings', 'getMeetingRoomItemsWithBookingsForDates'],
    });

    const { handleSubmit } = useForm<BookingFormData>();

    useEffect(() => {
        if (meetingRoomItem) {
            setAvailabilityText(meetingRoomItem.result[0].isAvailable ? 'Available' : 'Not available');
            setIsAvailable(meetingRoomItem.result[0].isAvailable);
        }
    }, [meetingRoomItem]);

    useEffect(() => {
        if (added) {
            setSelectedMenuItems(added?.booking?.menuPackages || []);
            setBookingResources(added?.booking?.bookingResources || []);
            const newAddOns: BookingRoomExtra[] = added?.booking?.roomExtras.map((roomExtraData: any) => {
                return {
                    id: roomExtraData?.id,
                    title: roomExtraData?.roomExtra?.title,
                    quantity: roomExtraData?.quantity,
                    totalPrice: roomExtraData?.totalPrice,
                    rate: roomExtraData?.rate,
                    bookingId: added?.booking?.id,
                    roomExtraId: roomExtraData?.roomExtra?.id,
                };
            });
            setBookingRoomAdOns(newAddOns);
            if (quoteId) {
                setAddingQuote(true);
                const item = {
                    booking_id: added?.booking?.id,
                    quote_id: quoteId,
                    status_enum: 'pending',
                    quantity: bookingRate?.quantity,
                    price: bookingRate?.price,
                    discount: bookingRate?.discount || 0,
                    total_price: bookingRate?.totalPrice,
                    quoted_price: bookingRate?.quotedPrice || bookingRate?.totalPrice,
                    currency: 'zar',
                    unit: bookingRate?.unit,
                };

                if (sendEmail) {
                    sendEmailToMembers({
                        variables: {
                            bookingId: added?.booking?.id,
                        },
                    });
                }

                addQuoteItem({
                    variables: {
                        item: item,
                    },
                });
                return;
            }

            if (sendEmail) {
                sendEmailToMembers({
                    variables: {
                        bookingId: added?.booking?.id,
                    },
                });
            }

            if (!sendEmail) {
                showToast(!bookingId ? 'Successfully added a booking' : 'Successfully updated booking', 'success');
            }
            if (!sendEmail && !bookingId) handleClose();
        }

        if (errorAdding) {
            showToast(
                !bookingId
                    ? 'An error has occurred while trying to add a booking'
                    : 'An error has occurred while trying to update this booking',
                'danger',
            );
        }

        if (checkingError) {
            showToast('An error has occurred while trying to check fo an available booking', 'danger');
        }
    }, [added, errorAdding, calledToCheck, checkingError]);

    const closeDialog = () => {
        setShowFullScreen(false);
        if (handleClose) handleClose();
    };

    useEffect(() => {
        if (addedQuote) {
            if (!bookingId) {
                showToast('Successfully added a quote item', 'success');
                handleClose();
            }

            if (loadedBooking && selectedMenuItems.length === 0 && called) {
                setSelectedMenuItems(loadedBooking?.booking?.menuPackages || []);
            }

            if (loadedBooking && bookingResources.length === 0 && called) {
                setBookingResources(loadedBooking?.booking?.bookingResources || []);
            }

            if (loadedBooking && documents.length === 0 && called) {
                setDocuments(loadedBooking?.booking?.documents);
            }

            if (loadedBooking && notes.length === 0 && called) {
                setNotes(loadedBooking?.booking?.notes);
            }

            showToast('Successfully updated booking', 'success');
            setAddingQuote(false);
        }

        if (quoteError) {
            showToast('An error has occurred while trying to add a quote.', 'danger');
            setAddingQuote(false);
        }
        if (emailSent) {
            showToast('Email has been sent to members', 'success');
            setSendEmail(false);
        }

        if (emailError) {
            showToast('An error has occurred while trying to send the email', 'danger');
            setSendEmail(false);
            console.log('Email error', emailError);
        }
    }, [addedQuote, quoteError, emailError, emailSent]);

    useEffect(() => {
        if (meetingRoom) {
            setAvailabilityText(meetingRoom.is_room_available[0].isAvailable ? 'Available' : 'Not available');

            setIsAvailable(meetingRoom.is_room_available[0].isAvailable);
        }

        if (loadedBooking && selectedMenuItems.length === 0 && called) {
            //console.log("Triggered...");
            setSelectedMenuItems(loadedBooking?.booking?.menuPackages || []);
        }

        if (loadedBooking && documents.length === 0 && called) {
            setDocuments(loadedBooking?.booking?.documents);
        }

        if (loadedBooking && !configurationId && called) {
            setConfigurationId(loadedBooking?.booking?.configurationId);
        }

        if (loadedBooking && !voucherId) {
            setVoucherId(loadedBooking?.booking?.voucherId);
        }

        if (loadedBooking && bookingResources.length === 0 && called) {
            setBookingResources(loadedBooking?.booking?.bookingResources || []);
        }

        if (loadedBooking && notes.length === 0 && called) {
            setNotes(loadedBooking?.booking?.notes);
        }

        if (loadedBooking && bookingRoomAdOns.length === 0 && called) {
            const newAddOns: BookingRoomExtra[] = loadedBooking?.booking?.roomExtras.map((roomExtraData: any) => {
                return {
                    id: roomExtraData?.id,
                    title: roomExtraData?.roomExtra?.title,
                    quantity: roomExtraData?.quantity,
                    totalPrice: roomExtraData?.totalPrice,
                    rate: roomExtraData?.rate,
                    bookingId: added?.booking?.id,
                    roomExtraId: roomExtraData?.roomExtra?.id,
                };
            });
            //console.log("Add-ons", newAddOns);
            setBookingRoomAdOns(newAddOns);
        }

        if (loadedBooking && !nominatedMembers && called) {
            const members: NominatedMember[] = loadedBooking?.booking?.nominatedMember?.map((m) => {
                return {
                    id: m?.member?.id,
                    bookingId: loadedBooking?.booking?.id,
                    name: m?.member?.user.name,
                    surname: m?.member?.user.surname,
                };
            });

            setNominatedMembers(members);
        }

        if (loadedBooking && called)
            if (loadedBooking && !rateData) {
                const rate: BookingRate = {
                    discount: loadedBooking?.booking.discount,
                    price: loadedBooking?.booking.price,
                    totalPrice: loadedBooking?.booking.totalPrice,
                    quotedPrice: loadedBooking?.booking.quotedPrice || loadedBooking?.booking.totalPrice,
                    unit: loadedBooking?.booking.unit,
                    quantity: loadedBooking?.booking.quantity,
                };

                setBookingRate(rate);
            }
    }, [meetingRoom, loadedBooking]);

    useEffect(() => {
        if (rateData) {
            const rate: BookingRate = {
                discount: rateData?.rate?.discount,
                price: rateData?.rate?.price,
                totalPrice: rateData?.rate?.totalPrice,
                quotedPrice: rateData?.rate?.quotedPrice ? rateData?.rate?.quotedPrice : rateData?.rate?.totalPrice,
                unit: rateData?.rate?.unit,
                quantity: rateData?.rate?.quantity,
            };

            setBookingRate(rate);
        }
    }, [rateData]);

    useEffect(() => {
        if (formData?.start || formData?.end || formData?.meetingRoomId || formData?.date) {
            if (formData.recurringPeriod === 'none') {
                checkBookingAvailability();
                console.log('Triggered');
            } else {
                checkRepeatingBookingAvailability();
            }
            getRates();
        }
    }, [
        formData?.start,
        formData?.end,
        formData?.meetingRoomId,
        formData?.date,
        formData?.endDate,
        formData?.recurringPeriod,
    ]);

    useEffect(() => {
        if (sendEmail) {
            addBooking();
        }
    }, [sendEmail]);

    const onSubmit = async (data: BookingFormData) => {
        await addBooking();
    };

    const addBooking = async () => {
        let uploadFailed = false;
        const timeFrom = !formData?.isFullDay
            ? moment(formData?.date)
                  ?.utc(true)
                  .hours(getTime(formData?.start)[0])
                  .minutes(getTime(formData?.start)[1])
                  .seconds(0)
                  .milliseconds(0)
                  .toISOString()
            : moment(formData?.date)?.utc(true).hours(8).minutes(0).seconds(0).milliseconds(0).toISOString();

        const timeTo = !formData.isFullDay
            ? moment(formData.date)
                  ?.utc(true)
                  .hours(getTime(formData?.end)[0])
                  .minutes(getTime(formData?.end)[1])
                  .seconds(0)
                  .milliseconds(0)
                  .toISOString()
            : moment(formData?.longTermEndDate)
                  ?.utc(true)
                  .hours(17)
                  .minutes(0)
                  .seconds(0)
                  .milliseconds(0)
                  .toISOString();

        setIsUploading(true);
        const newConferenceDocuments = documents.map(async (doc) => {
            try {
                let path = doc?.documentPath || '';
                if (doc.selectedFile) {
                    path = `conference_documents/${v4()}_${doc.selectedFile.name}`;
                    await Storage.put(path, doc.selectedFile, {
                        level: 'public',
                    });
                }

                return {
                    id: doc?.id,
                    name: doc.name,
                    documentPath: path,
                };
            } catch (error) {
                console.log('An error has occurred while trying to upload the document', error);
                uploadFailed = true;
                showToast('An error has occurred while trying to upload the documents', 'danger');
                setIsUploading(false);
            }
        });

        if (uploadFailed) {
            return;
        }

        const docs = await Promise.all(newConferenceDocuments);

        setIsUploading(false);

        const booking = {
            id: bookingId || undefined,
            subject: formData?.title,
            time_from: timeFrom,
            time_to: timeTo,
            access_code: formData?.accessCode !== '' ? formData?.accessCode : null,
            send_communications: formData?.sendCommunication,
            include_calendar_invites: formData?.includeCalendarInvites,
            configuration_id: configurationId,
            voucher_id: voucherId || undefined,
            has_custom_pricing: formData.isCustomPricing,
            price: formData.isCustomPricing ? formData.customPrice : bookingRate?.price,
            status_enum: bookingId ? formData?.status : 'pending',
            unit: bookingRate?.unit,
            quote_id: quoteId ?? undefined,
            billing_period: formData?.isCustomPricing ? formData?.billingPeriod : bookingRate?.unit,
            discount: !formData.isCustomPricing ? bookingRate?.discount || 0 : 0,
            total_price: formData.isCustomPricing ? formData.customPrice : bookingRate?.totalPrice,
            quoted_price: formData.isCustomPricing
                ? formData.customPrice
                : bookingRate?.quotedPrice || bookingRate?.totalPrice,
            quantity: !formData.isCustomPricing ? bookingRate?.quantity : 1,
            recurring_schedule: {
                on_conflict: {
                    constraint: 'booking_recurring_schedule_booking_id_key',
                    update_columns: ['ends_on', 'period', 'type_enum'],
                },
                data: {
                    is_recurring: formData.recurringPeriod !== 'none',
                    type_enum: formData.recurringPeriod !== 'none' ? formData.recurringPeriod : 'daily',
                    period: formData.recurringPeriod,
                    ends_on: moment(formData.endDate).toISOString(),
                },
            },
            meeting_room_item_id: formData.meetingRoomId,
            reference: generate(10).toUpperCase(),
            account_id: clientId ? clientId : formData.client,
            booking_guests: {
                on_conflict: {
                    constraint: 'booking_guest_booking_id_email_key',
                    update_columns: [],
                },
                data: formData.guests?.map((guest) => {
                    return {
                        email: guest.trim(),
                    };
                }),
            },
            notes: {
                on_conflict: {
                    constraint: 'booking_note_pkey',
                    update_columns: ['notes', 'updated_at'],
                },
                data: notes.map((note) => {
                    return {
                        id: note.id,
                        notes: note.notes,
                        user_id: user.id,
                    };
                }),
            },
            price_change_reason: formData.priceChangeReason,
            documents: {
                on_conflict: {
                    constraint: 'booking_document_pkey',
                    update_columns: ['name', 'document_path'],
                },
                data: docs?.map((doc) => {
                    return {
                        id: doc?.id,
                        name: doc.name,
                        document_path: doc.documentPath,
                    };
                }),
            },

            voucher_code: formData?.voucherCode || undefined,
            type_enum: quoteId ? 'quote' : 'manual',
            additional_notes: formData.description,
            is_long_term: formData.isFullDay,
            checklist_add_ons: {
                on_conflict: {
                    constraint: 'booking_add_on_booking_id_checklist_item_id_key',
                    update_columns: [],
                },
                data:
                    formData.checklistItems?.map((item) => {
                        return {
                            checklist_item_id: item,
                        };
                    }) || [],
            },
            nominated_members: {
                on_conflict: {
                    constraint: 'nominated_member_booking_id_member_id_key',
                    update_columns: [],
                },
                data:
                    nominatedMembers?.map((member) => {
                        return {
                            member_id: member.id,
                        };
                    }) || [],
            },
            booking_after_hour_resources: {
                on_conflict: {
                    constraint: 'booking_after_hour_resource_pkey',
                    update_columns: ['total_price', 'quantity'],
                },
                data: bookingResources?.map((resource) => {
                    return {
                        id: resource?.id,
                        quantity: resource?.quantity,
                        total_price: resource?.totalPrice,
                        name: resource?.name,
                        resource_id: resource?.resourceId,
                        rate: resource?.rate,
                    };
                }),
            },
            room_extras: {
                on_conflict: {
                    constraint: 'booking_room_extra_pkey',
                    update_columns: ['total_price', 'quantity'],
                },
                data: bookingRoomAdOns.map((adOn) => {
                    return {
                        id: adOn?.id,
                        room_extra_id: adOn.roomExtraId,
                        quantity: adOn.quantity,
                        total_price: adOn.totalPrice,
                        rate: adOn.rate,
                    };
                }),
            },
            menu_packages: {
                on_conflict: {
                    constraint: 'booking_menu_package_pkey',
                    update_columns: ['total_price', 'quantity'],
                },
                data: selectedMenuItems.map((item) => {
                    return {
                        id: item?.id,
                        vendor_id: item?.vendorId,
                        price: item?.unitPrice,
                        total_price: item?.totalPrice,
                        quantity: item?.quantity,
                        menu_package_name: item?.menuPackageName,
                        menu_package_id: item?.menuPackageId,
                        vendor_name: item?.vendorName,
                        featured_image: item?.featuredImage,
                    };
                }),
            },
        };

        console.log('Booking', booking);

        addBookingMutation({
            variables: {
                booking: booking,
            },
        });
    };

    const getRates = useCallback(() => {
        if (formData?.meetingRoomId === 'empty') {
            return;
        }

        if ((formData?.start === 'empty' || formData?.end === 'empty') && !formData?.isFullDay) {
            return;
        }

        if (
            loadedBooking?.booking &&
            moment(loadedBooking?.booking?.from).format('HH:mm') === formData?.start &&
            moment(loadedBooking?.booking?.to).format('HH:mm') === formData?.end &&
            loadedBooking?.booking?.meetingRoomItem.id === formData?.meetingRoomId
        ) {
            return;
        }

        if (formData?.client === '' && !clientId) {
            return;
        }

        if (formData?.isCustomPricing) {
            return;
        }

        const accountId = clientId ? clientId : formData?.client;

        const args = {
            from_date: !formData?.isFullDay
                ? moment(formData?.date)
                      ?.utc(true)
                      .hours(getTime(formData?.start)[0])
                      .minutes(getTime(formData?.start)[1])
                      .format('YYYY-MM-DD HH:mm:ss')
                : moment(formData?.date)?.utc(true).hours(8).minutes(0).format('YYYY-MM-DD HH:mm:ss'),
            to_date: !formData.isFullDay
                ? moment(formData.date)
                      ?.utc(true)
                      .hours(getTime(formData?.end)[0])
                      .minutes(getTime(formData?.end)[1])
                      .format('YYYY-MM-DD HH:mm:ss')
                : moment(formData?.longTermEndDate)?.utc(true).hours(17).minutes(0).format('YYYY-MM-DD HH:mm:ss'),
            selected_account_id: accountId,
            selected_meeting_room_id: formData?.meetingRoomId,
        };
        addBookingRate({
            variables: {
                args: args,
            },
        });
    }, [formData?.meetingRoomId, formData?.start, formData?.end]);

    const checkBookingAvailability = useCallback(() => {
        if (formData?.meetingRoomId === 'empty') {
            return;
        }

        if (
            loadedBooking?.booking &&
            moment(loadedBooking?.booking?.from).format('HH:mm') === formData?.start &&
            moment(loadedBooking?.booking?.to).format('HH:mm') === formData?.end &&
            loadedBooking?.booking?.meetingRoomItem.id === formData?.meetingRoomId &&
            moment(loadedBooking?.booking?.from).format('yyyy-MM-DD') === moment(formData.date).format('yyyy-MM-DD') &&
            !calledToCheck
        ) {
            return;
        }

        if ((formData.start === 'empty' || formData.end === 'empty') && !formData?.isFullDay) {
            return;
        }

        const args = {
            s_meeting_room_item_id: formData?.meetingRoomId,
            from_date: !formData?.isFullDay
                ? moment(formData?.date)
                      ?.utc(true)
                      .hours(getTime(formData?.start)[0])
                      .minutes(getTime(formData?.start)[1])
                      .seconds(0)
                      .format('YYYY-MM-DD HH:mm:ss')
                : moment(formData?.date)?.utc(true).hours(8).minutes(0).format('YYYY-MM-DD HH:mm:ss'),
            to_date: !formData.isFullDay
                ? moment(formData.date)
                      ?.utc(true)
                      .hours(getTime(formData?.end)[0])
                      .minutes(getTime(formData?.end)[1])
                      .seconds(0)
                      .format('YYYY-MM-DD HH:mm:ss')
                : moment(formData?.longTermEndDate)?.utc(true).hours(17).minutes(0).format('YYYY-MM-DD HH:mm:ss'),
            s_booking_id: bookingId,
            booking_type: !isClosedDate ? 'manual_booking' : null,
        };

        checkMeetingRoom({
            variables: {
                args: args,
            },
        });
    }, [formData?.meetingRoomId, formData?.start, formData?.end, formData?.longTermEndDate, formData?.date]);

    const checkRepeatingBookingAvailability = () => {
        if (formData?.meetingRoomId === 'empty') {
            return;
        }

        if (
            loadedBooking?.booking &&
            moment(loadedBooking?.booking?.from).format('HH:mm') === formData?.start &&
            moment(loadedBooking?.booking?.to).format('HH:mm') === formData?.end &&
            loadedBooking?.booking?.meetingRoomItem.id === formData?.meetingRoomId
        ) {
            return;
        }

        if ((formData.start === 'empty' || formData.end === 'empty') && !formData?.isFullDay) {
            return;
        }

        if (formData.recurringPeriod === 'none') {
            return;
        }

        if (!formData?.endDate) {
            return;
        }

        const args = {
            s_meeting_room_item_id: formData?.meetingRoomId,
            recurring_end_date: moment(formData.endDate).toISOString(),
            recurring_type: formData.recurringPeriod,
            from_date: !formData?.isFullDay
                ? moment(formData?.date)
                      ?.utc(true)
                      .hours(getTime(formData?.start)[0])
                      .minutes(getTime(formData?.start)[1])
                      .seconds(0)
                      .format('YYYY-MM-DD HH:mm:ss')
                : moment(formData?.date)?.utc(true).hours(8).minutes(0).format('YYYY-MM-DD HH:mm:ss'),
            to_date: !formData.isFullDay
                ? moment(formData.date)
                      ?.utc(true)
                      .hours(getTime(formData?.end)[0])
                      .minutes(getTime(formData?.end)[1])
                      .seconds(0)
                      .format('YYYY-MM-DD HH:mm:ss')
                : moment(formData?.longTermEndDate)?.utc(true).hours(17).minutes(0).format('YYYY-MM-DD HH:mm:ss'),
        };

        checkAvailabilityForRecurring({
            variables: {
                args: args,
            },
        });
    };

    const booking: Booking = loadedBooking?.booking;

    return (
        <>
            {Notification}
            <Dialog
                fullScreen={showFullScreen}
                open={open}
                fullWidth={true}
                maxWidth='sm'
                onClose={closeDialog}
                TransitionComponent={Transition}>
                {!showFullScreen && (
                    <AppBar sx={{ position: 'relative' }} elevation={0} style={{ background: '#F1F3F4' }}>
                        <Toolbar
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                minHeight: '48px',
                            }}>
                            <IconButton edge='end' color='default' onClick={closeDialog} aria-label='close'>
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                )}
                <DialogContent>
                    {loadingBooking && <LinearProgress />}
                    {!loadingBooking && (
                        <ExtendedBookingForm
                            rooms={rooms}
                            sendEmail={sendEmail}
                            getSendEmail={setSendEmail}
                            setIsClosedDate={setIsClosedDate}
                            vendors={data?.vendor}
                            vendorMenuTypes={data?.mealType}
                            formData={formData}
                            selectedBooking={booking}
                            clientId={clientId}
                            addingBooking={addingBooking || isUploading}
                            selectedRoomId={roomItemId}
                            getFormData={setFormData}
                            addingQuote={addingQuote}
                            onFormSubmit={addBooking}
                            onClose={closeDialog}
                            getSelectedMenuItems={setSelectedMenuItems}
                            statusList={statusList}
                            isRoomAvailable={isAvailable}
                            availabilityText={availabilityText}
                            checkingAvailability={checking || checkingAvailability}
                            bookingRate={bookingRate}
                            selectedMenuItems={selectedMenuItems}
                            configurationId={configurationId}
                            getConfigurationId={setConfigurationId}
                            afterHourResources={afterHourResource?.resources}
                            getBookingResources={setBookingResources}
                            bookingResources={bookingResources}
                            nominatedMembers={nominatedMembers || []}
                            getNominatedMembers={setNominatedMembers}
                            getVoucherId={setVoucherId}
                            bookingRoomAdOns={bookingRoomAdOns}
                            getBookingRoomAdOns={setBookingRoomAdOns}
                            documents={documents}
                            getDocuments={setDocuments}
                            notes={notes}
                            getNotes={setNotes}
                        />
                    )}
                </DialogContent>
                {!showFullScreen && (
                    <DialogActions>
                        {checking && (
                            <span>
                                <Spinner />
                            </span>
                        )}
                        {booking?.requiresVerification && (
                            <p
                                style={{
                                    color: 'red',
                                    fontSize: 14,
                                    paddingRight: 12,
                                    marginTop: 16,
                                }}>
                                Booking Changes Under Review
                            </p>
                        )}
                        {!checking && (
                            <p
                                style={{
                                    color: isAvailable ? 'blue' : 'red',
                                    fontSize: 14,
                                    paddingRight: 12,
                                    marginTop: 16,
                                }}>
                                {availabilityText}
                            </p>
                        )}

                        <button
                            disabled={addingBooking || checking || addingQuote}
                            className='btn'
                            onClick={() => setShowFullScreen(true)}>
                            More options
                        </button>
                        <button
                            onClick={handleSubmit(onSubmit)}
                            type='submit'
                            className='btn btn-primary'
                            disabled={
                                addingBooking ||
                                checking ||
                                (!isAvailable && !bookingId) ||
                                addingQuote ||
                                !isAvailable ||
                                booking?.requiresVerification
                            }>
                            {!addingBooking && !addingQuote ? (
                                <span>Save</span>
                            ) : (
                                <span>
                                    <Spinner />
                                </span>
                            )}
                        </button>
                    </DialogActions>
                )}
            </Dialog>
        </>
    );
};

export default BookingDialog;
